import Client from "./client";

export default class Brand {
    static async getCatalogBrands(filterValue, dispatch) {
        try {
            return await Client.authGet(`/product/brand?brd=${filterValue}`, dispatch);
        } catch (err) {
            console.log(`fetchData error: ${err}`);
            return [];
        }
    }
}
