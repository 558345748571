import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

import AutoQuerybox from "../component/AutoQuerybox";
import Modalbox from "../component/Modalbox";
import LabelledCheckbox from "../component/LabelledCheckbox";
import Brand from "../model/brand";

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {
            width: "100%",
        },
        label: {
            textAlign: "right",
            float: "right",
            margin: 10,
            fontWeight: "bold",
        },
        value: {
            textAlign: "left",
        },
    });
});

export default function ModalBrandChange({ annotation, open, onClose, onSave }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [brandName, setBrandName] = useState(annotation.current_brand);
    const [catalogBrands, setCatalogBrands] = useState([]);
    const [catalogBrandMap, setCatalogBrandMap] = useState({});

    const [dontKnow, setDontKnow] = useState(false);
    const [disableSave, setDisableSave] = useState(true);

    const title = annotation.data_id !== null ? `Brand editing row ${annotation.data_id + 1}` : `Brand editing checked rows`;

    function unchanged(brandValue) {
        return brandValue === annotation.current_brand || brandValue === "";
    }

    return (
        <Modalbox
            title={title}
            open={open}
            onClose={onClose}
            onSave={() => {
                if (dontKnow) {
                    onSave(annotation.data_id, null, null);
                    return;
                }
                const catalogBrand = catalogBrandMap[brandName.toLowerCase()];
                catalogBrand ?
                    onSave(annotation.data_id, catalogBrand.id, catalogBrand.brand) :
                    onSave(annotation.data_id, null, brandName);
            }}
            disableSave={disableSave}
        >
            <Grid container className={classes.root}>
                <Grid item xs={4}>
                    <div className={classes.label}>Brand:</div>
                </Grid>
                <Grid item xs={8} className={classes.value}>
                    <AutoQuerybox
                        label="Set brand value"
                        value={brandName}
                        small
                        options={catalogBrands}
                        onChange={(value) => {
                            if (value.length === 0) setCatalogBrands([]);
                            setBrandName(value);
                            setDisableSave(unchanged(value));
                        }}
                        onDebounce={async (value) => {
                            try {
                                if (value.length === 0) {
                                    setCatalogBrands([]);
                                    return;
                                }

                                const res = await Brand.getCatalogBrands(value, dispatch);
                                const data = res.reduce((prev, cur) => {
                                    prev.values.push(cur.brand);
                                    prev.map[cur.brand.toLowerCase()] = cur;
                                    return prev;
                                }, { values: [], map: {} });

                                setCatalogBrands(data.values);
                                setCatalogBrandMap(data.map);
                            } catch (err) {
                                console.log(err);
                            }
                        }}
                        autoFocus
                        disabled={dontKnow}
                    />
                    <LabelledCheckbox
                        id="cb-dont-know"
                        label="Don't know"
                        value={dontKnow}
                        onChange={(check) => {
                            setDontKnow(check);
                            check ? setDisableSave(!check) : setDisableSave(unchanged(brandName));
                        }}
                        color="primary"
                        width={60}
                        style={{ float: "left", marginTop: -8 }}
                    />
                </Grid>
            </Grid>
        </Modalbox>
    );
}