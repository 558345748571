import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import Selector from "../component/Selector";
import Modalbox from "../component/Modalbox";
import LabelledCheckbox from "../component/LabelledCheckbox";

import Taxonomy from "../model/taxonomy";
import { selectTaxonomy, setTaxonomy } from "../model/reduxClientSlice";
import Loading from "../component/Loading";

const SELECTOR_WIDTH = 240;
const SELECTOR_GRID_SIZE = 7;
const CHECKBOX_MARGIN_TOP = 3;

const useStyles = makeStyles((_theme) => {
    return createStyles({
        root: {
            width: "100%",
        },
        label: {
            textAlign: "right",
            float: "right",
            margin: 10,
            fontWeight: "bold",
        },
        value: {
            textAlign: "left",
        },
    });
});

export default function ModalTaxonomyChange({ annotation, open, onClose, onSave }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const taxonomy = useSelector(selectTaxonomy);

    const [oSectorId, setOSectorId] = useState(0);
    const [oDepartmentId, setODepartmentId] = useState(0);
    const [oCategoryId, setOCategoryId] = useState(0);

    const [sectorId, setSectorId] = useState(0);
    const [departmentId, setDepartmentId] = useState(0);
    const [categoryId, setCategoryId] = useState(0);

    const [sectorUnresolved, setSectorUnresolved] = useState(false);
    const [departmentUnresolved, setDepartmentUnresolved] = useState(false);
    const [categoryUnresolved, setCategoryUnresolved] = useState(false);

    const [disableSave, setDisableSave] = useState(true);

    const limit = 40;
    const string = annotation.receipt_short_description;
    const title = string ? (string.length > limit ? `${string.substring(0, limit + 1)} ..` : string) : "Editing checked rows";

    function unchanged(sectorValue, departmentValue, categoryValue) {
        return sectorValue === oSectorId && departmentValue === oDepartmentId && categoryValue === oCategoryId;
    }

    async function loadTaxonomy() {
        if (taxonomy) {
            const sectId = taxonomy.sectors().indexOf(annotation.current_sector);
            const deptId = taxonomy.departments(annotation.current_sector).indexOf(annotation.current_department);
            const catId = taxonomy.categories(annotation.current_sector, annotation.current_department).indexOf(annotation.current_category)

            setSectorId(sectId < 0 ? 0 : sectId);
            setDepartmentId(deptId < 0 ? 0 : deptId);
            setCategoryId(catId < 0 ? 0 : catId);

            setOSectorId(sectId < 0 ? 0 : sectId);
            setODepartmentId(deptId < 0 ? 0 : deptId);
            setOCategoryId(catId < 0 ? 0 : catId);
            return;
        }

        const t = await Taxonomy.getTaxonomy(dispatch);
        if (t) dispatch(setTaxonomy({ taxonomy: t }));

        const sectId = t.sectors().indexOf(annotation.current_sector);
        const deptId = t.departments(annotation.current_sector).indexOf(annotation.current_department);
        const catId = t.categories(annotation.current_sector, annotation.current_department).indexOf(annotation.current_category)

        setSectorId(sectId < 0 ? 0 : sectId);
        setDepartmentId(deptId < 0 ? 0 : deptId);
        setCategoryId(catId < 0 ? 0 : catId);

        setOSectorId(sectId < 0 ? 0 : sectId);
        setODepartmentId(deptId < 0 ? 0 : deptId);
        setOCategoryId(catId < 0 ? 0 : catId);
    }

    useEffect(() => { loadTaxonomy(); }, []);

    return (
        <Modalbox
            title={title}
            open={open}
            onClose={onClose}
            onSave={() => {
                if (sectorUnresolved) {
                    onSave(annotation.data_id, null, null, null, null);
                    return;
                }

                if (departmentUnresolved) {
                    const sector = taxonomy.sectors()[sectorId];
                    onSave(annotation.data_id, null, sector, null, null);
                    return;
                }

                if (categoryUnresolved) {
                    const sector = taxonomy.sectors()[sectorId];
                    const department = taxonomy.departments(sector)[departmentId];
                    onSave(annotation.data_id, null, sector, department, null);
                    return;
                }

                const sector = taxonomy.sectors()[sectorId];
                const department = taxonomy.departments(sector)[departmentId];
                const category = taxonomy.categories(sector, department)[categoryId];
                const id = taxonomy.idFromSelection(sector, department, category);
                onSave(annotation.data_id, id, sector, department, category);
            }}
            disableSave={disableSave}
        >
            {taxonomy ?

                <Grid container className={classes.root}>
                    <Grid item xs={SELECTOR_GRID_SIZE} className={classes.value}>
                        <Selector
                            id="id-sector"
                            label="Sector"
                            value={sectorId}
                            selections={taxonomy.sectors()}
                            onChange={(id) => {
                                setSectorId(id);
                                setDepartmentId(0);
                                setCategoryId(0);
                                setDisableSave(unchanged(id, departmentId, categoryId));
                            }}
                            width={SELECTOR_WIDTH}
                            disabled={sectorUnresolved}
                        />
                    </Grid>
                    <Grid item xs={12 - SELECTOR_GRID_SIZE} className={classes.value}>
                        <LabelledCheckbox
                            id="id-sector-unresolved"
                            label="Don't know"
                            value={sectorUnresolved}
                            onChange={(check) => {
                                setSectorUnresolved(check);
                                setDepartmentUnresolved(check);
                                setCategoryUnresolved(check);
                                check ?
                                    setDisableSave(false) :
                                    setDisableSave(unchanged(sectorId, departmentId, categoryId));
                            }}
                            color="primary"
                            width={60}
                            style={{ float: "left", marginTop: CHECKBOX_MARGIN_TOP }}
                        />
                    </Grid>

                    <Grid item xs={SELECTOR_GRID_SIZE} className={classes.value}>
                        <Selector
                            id="id-department"
                            label="Department"
                            value={departmentId}
                            selections={taxonomy.departments(taxonomy.sectors()[sectorId])}
                            onChange={(id) => {
                                setDepartmentId(id);
                                setCategoryId(0);
                                setDisableSave(unchanged(sectorId, id, 0));
                            }}
                            width={SELECTOR_WIDTH}
                            disabled={departmentUnresolved}
                        />
                    </Grid>
                    <Grid item xs={12 - SELECTOR_GRID_SIZE} className={classes.value}>
                        <LabelledCheckbox
                            id="id-department-unresolved"
                            label="Don't know"
                            value={departmentUnresolved}
                            onChange={(check) => {
                                console.log('department change');
                                if (check) {
                                    setDisableSave(false);
                                } else {
                                    setSectorUnresolved(false);
                                    setDisableSave(unchanged(sectorId, departmentId, categoryId));
                                }
                                setDepartmentUnresolved(check);
                                setCategoryUnresolved(check);
                            }}
                            color="primary"
                            width={60}
                            style={{ float: "left", marginTop: CHECKBOX_MARGIN_TOP }}
                        />
                    </Grid>

                    <Grid item xs={SELECTOR_GRID_SIZE} className={classes.value}>
                        <Selector
                            id="id-categories"
                            label="Category"
                            value={categoryId}
                            selections={taxonomy.categories(taxonomy.sectors()[sectorId], taxonomy.departments(taxonomy.sectors()[sectorId])[departmentId])}
                            onChange={(id) => {
                                setCategoryId(id);
                                setDisableSave(unchanged(sectorId, departmentId, id));
                            }}
                            width={SELECTOR_WIDTH}
                            disabled={categoryUnresolved}
                        />
                    </Grid>
                    <Grid item xs={12 - SELECTOR_GRID_SIZE} className={classes.value}>
                        <LabelledCheckbox
                            id="id-category-unresolved"
                            label="Don't know"
                            value={categoryUnresolved}
                            onChange={(check) => {
                                if (check) {
                                    setDisableSave(false);
                                } else {
                                    setSectorUnresolved(false);
                                    setDepartmentUnresolved(false);
                                    setDisableSave(unchanged(sectorId, departmentId, categoryId));
                                }
                                setCategoryUnresolved(check);
                            }}
                            color="primary"
                            width={60}
                            style={{ float: "left", marginTop: CHECKBOX_MARGIN_TOP }}
                        />
                    </Grid>

                </Grid> :
                <Loading size={35} />
            }
        </Modalbox >
    );
}