import Client from "./client";

const ord = "order_by";
const dsc = "desc";
const nbr = "no_brand";

export default function mixinBase(obj, prefix, state, baseUrl, queryOrderById, queryDesc) {
    obj.state = state;
    obj.baseUrl = baseUrl;
    obj.data = [];

    // ------------ //
    // query mixins //
    // ------------ //

    obj.queryOrderBy = parseInt(localStorage.getItem(parm(prefix, ord)) || `${queryOrderById}`);
    obj.queryDesc = parseInt(localStorage.getItem(parm(prefix, dsc)) || `${queryDesc ? 1 : 0}`);
    obj.queryNoBrand = parseInt(localStorage.getItem(parm(prefix, nbr)) || "0");

    obj.setQueryOrderBy = (id) => {
        obj.queryOrderBy = id;
        localStorage.setItem(parm(prefix, ord), id);
    };

    obj.setQueryDesc = (id) => {
        obj.queryDesc = id;
        localStorage.setItem(parm(prefix, dsc), id);
    };

    obj.setQueryNoBrand = (value) => {
        obj.queryNoBrand = value;
        localStorage.setItem(parm(prefix, nbr), value);
    };

    obj.queryString = () => {
        return `?nbd=${obj.queryNoBrand === 1}&ord=${obj.queryOrderBy}&dsc=${obj.queryDesc === 1}&rsd=&mch=&pct=0&ctg=&sid=0`;
    }

    obj.sortData = () => {
        if (obj.data.length === 0) return false;

        const keys = ["receipt_short_description", "current_category", "num_sales", "current_brand"];
        if (obj.queryOrderBy < 0 || obj.queryOrderBy >= keys.length) return false;
        
        const key = keys[obj.queryOrderBy]
        const desc = obj.queryDesc === 1;

        let sorted = false;
        obj.data.sort((a, b) => {
            if (a[key] === b[key]) return 0;
            sorted = true;
            if (a[key] > b[key]) return desc ? -1 : 1;
            return desc ? 1 : -1;
        });

        return sorted;
    };

    // ----------- //
    // data mixins //
    // ----------- //

    obj.getData = async (dispatch) => {
        try {
            const path = `${obj.baseUrl}${obj.queryString()}`;
            const data = await Client.authGet(path, dispatch);
            obj.data = data.annotations;
            return data.annotations;
        } catch (err) {
            console.log(`fetchData error: ${err}`);
            return null;
        }
    };

    obj.getDataCount = async (dispatch) => {
        try {
            let data = await Client.authGet(`${obj.baseUrl}/count${obj.queryString()}`, dispatch);
            return data.count;
        } catch (err) {
            console.log(`fetchData error: ${err}`);
            return null;
        }
    };

    obj.dataLength = () => {
        return obj.data.length;
    };
}

function parm(prefix, postfix) {
    return `${prefix}_${postfix}`;
}