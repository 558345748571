import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import Modalbox from "../component/Modalbox";
import AutoQuerybox from "../component/AutoQuerybox";
import Numbox from "../component/Numbox";
import { DATA_FILTER_RSD, DATA_FILTER_MERCHANT, DATA_FILTER_CATEGORY } from "../model/dataFilter";

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {
            width: "100%",
        },
        label: {
            textAlign: "right",
            float: "right",
            margin: 10,
            fontWeight: "bold",
        },
        value: {
            textAlign: "left",
        },
    });
});

export default function ModalQueryFilter({ data, dataFilter, open, onClose, onSave }) {
    const classes = useStyles();
    const [disableSave, setDisableSave] = useState(true);

    const [receiptShortDescription, setReceiptShortDescription] = useState(dataFilter.rsdFilter);
    const [merchantName, setMerchantName] = useState(dataFilter.merchantFilter);
    const [numSales, setNumSales] = useState(dataFilter.salesFilter);
    const [category, setCategory] = useState(dataFilter.categoryFilter);

    const [rsdOptions, setRsdOptions] = useState([]);
    const [merchantOptions, setMerchantOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);

    const labelWidth = 5;
    function unchanged(receiptShortDescription, merchantName, numSales, category) {
        const unchanged =
            receiptShortDescription === dataFilter.rsdFilter &&
            merchantName === dataFilter.merchantFilter &&
            numSales === dataFilter.salesFilter &&
            category === dataFilter.categoryFitler;
        return unchanged;
    }

    function onDebounce(filterType, setOptions) {
        return async (value) => {
            try {
                if (value.length === 0) {
                    setOptions([]);
                    return;
                }
                const suggestions = dataFilter.filterSuggestions(data, filterType, value);
                setOptions(suggestions);
            } catch (err) {
                console.log(err);
            }
        };
    }

    return (
        <Modalbox
            title={"Query Filter"}
            open={open}
            onClose={onClose}
            onSave={() => {
                if (unchanged()) return;
                onSave(receiptShortDescription, merchantName, numSales, category);
            }}
            disableSave={disableSave}
        >
            <Grid container className={classes.root}>
                <Grid item xs={labelWidth}>
                    <div className={classes.label}>RSD:</div>
                </Grid>
                <Grid item xs={12 - labelWidth} className={classes.value}>
                    <AutoQuerybox
                        label="Filter by RSD"
                        value={receiptShortDescription}
                        small
                        options={rsdOptions}
                        onChange={(value) => {
                            if (value.length === 0) setRsdOptions([]);
                            setReceiptShortDescription(value);
                            setDisableSave(
                                unchanged(value, merchantName, numSales, category)
                            );
                        }}
                        onDebounce={onDebounce(DATA_FILTER_RSD, setRsdOptions)}
                        autoFocus
                    />
                </Grid>

                <Grid item xs={labelWidth}>
                    <div className={classes.label}>Merchant:</div>
                </Grid>
                <Grid item xs={12 - labelWidth} className={classes.value}>
                    <AutoQuerybox
                        label="Filter by merchant"
                        value={merchantName}
                        small
                        options={merchantOptions}
                        onChange={(value) => {
                            setMerchantName(value);
                            setDisableSave(unchanged(receiptShortDescription, value, numSales, category));
                        }}
                        onDebounce={onDebounce(DATA_FILTER_MERCHANT, setMerchantOptions)}
                    />
                </Grid>

                <Grid item xs={labelWidth}>
                    <div className={classes.label}>Purchase count:</div>
                </Grid>
                <Grid item xs={12 - labelWidth} className={classes.value}>
                    <Numbox
                        label="Filter by purchase count"
                        value={numSales}
                        small
                        onChange={(value) => {
                            setNumSales(value);
                            setDisableSave(unchanged(receiptShortDescription, merchantName, value, category));
                        }}
                    />
                </Grid>

                <Grid item xs={labelWidth}>
                    <div className={classes.label}>Major Category:</div>
                </Grid>
                <Grid item xs={12 - labelWidth} className={classes.value}>
                    <AutoQuerybox
                        label="Filter by category"
                        value={category}
                        small
                        options={categoryOptions}
                        onChange={(value) => {
                            setCategory(value);
                            setDisableSave(unchanged(receiptShortDescription, merchantName, numSales, value));
                        }}
                        onDebounce={onDebounce(DATA_FILTER_CATEGORY, setCategoryOptions)}
                    />
                </Grid>
            </Grid>
        </Modalbox>
    );
}
