import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import Textbox from "../component/Textbox";
import Modalbox from "../component/Modalbox";

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {
            width: "100%",
        },
        label: {
            textAlign: "left",
            float: "left",
            marginRight: 5,
            fontWeight: "bold",
        },
        value: {
            textAlign: "left",
        },
    });
});

export default function ModalBrandAutosetProposal({ open, onClose, onSave, annotation }) {
    const classes = useStyles();
    const [disableSave, setDisableSave] = useState(false);
    const [prefix, setPrefix] = useState(annotation.receipt_short_description.split(" ")[0]);
    const [confirm, setConfirm] = useState(false);

    return (
        <Modalbox
            title={confirm ? "Confirm Auto Set" : "Auto Set"}
            open={open}
            onClose={() => confirm ? setConfirm(false) : onClose()}
            onSave={() => confirm ? onSave(annotation) : setConfirm(true)}
            disableSave={disableSave}
            saveText={confirm ? "Yes" : null}
            cancelText={confirm ? "No" : null}
        >
            {confirm ?
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <div>
                        Approve all RSDs that begin with <b>{prefix}</b> at <b>{annotation.merchant_name}</b>?
                    </div>
                </Grid> :
                <Grid container className={classes.root}>
                    <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <div><span className={classes.label}>Merchant:</span>{annotation.merchant_name}</div>
                        <div><span className={classes.label}>Brand:</span>{annotation.predicted_brand}</div>
                    </Grid>
                    <Grid item xs={12} className={classes.value}>
                        <Textbox
                            label="RSD prefix"
                            value={prefix}
                            small
                            onChange={(value) => {
                                setPrefix(value);
                                setDisableSave(value === "");
                            }}
                            autoFocus
                        />
                    </Grid>
                </Grid>
            }
        </Modalbox>
    );
}