import Client from "./client";

// notes:
// obj.data comes from base mixin
// obj.baseUrl comes from base mixin

// mixin introduces following properties into data annotation
// correction: object

export default function mixinCorrector(obj, isBrand) {
    obj.getCorrection = (dataId) => {
        if (!dataIdValid(obj, dataId)) return null;
        const correction = obj.data[dataId].correction;
        return typeof correction === 'undefined' ? null : correction;
    };

    obj.numCorrections = () => {
        return obj.data.reduce((prev, cur, _idx) => {
            return prev + (typeof cur.correction === "undefined" ? 0 : 1);
        }, 0);
    };

    obj.saveCorrections = async (dispatch) => {
        const changeMap = obj.data.reduce((prev, cur, _idx) => {
            if (typeof cur.correction === "undefined")
                return prev;

            if (typeof prev[cur.correction.key] === "undefined") {
                const correction = { ...cur.correction, annotation_keys: [cur.key] };
                delete correction.key;
                prev[cur.correction.key] = correction;
            } else {
                prev[cur.correction.key].annotation_keys.push(cur.key);
            }

            return prev;
        }, {});

        const changes = Object.values(changeMap);
        try {
            const res = await Client.authPut(obj.baseUrl, { changes }, dispatch);
            return res === null ? changes : res;
        } catch (err) {
            console.log(`saveAnnotationChanges error: ${err}`);
            return null;
        }
    };

    obj.removeCorrectedAnnotations = (changeMaps) => {
        const annotationKeys = changeMaps.reduce((prev, cur, _idx) => prev.concat(cur.annotation_keys), []);
        const a = obj.data.filter(x => !annotationKeys.includes(x.key));
        obj.data = a;
    };

    if (isBrand) {
        obj.setBrandCorrections = (dataIds, catalogBrandId, brandName) => {
            dataIds.forEach(dataId => {
                if (!dataIdValid(obj, dataId)) return;
                const key = `${catalogBrandId ? catalogBrandId : "x"}|${brandName ? brandName : "x"}`;
                obj.data[dataId].correction = {
                    key, catalog_brand_id: catalogBrandId, catalog_brand: brandName
                };
            });
        };

        obj.saveBrandProposal = async (proposals, dispatch) => {
            try {
                return await Client.authPost(`${obj.baseUrl}/proposal`, { proposals }, dispatch);
            } catch (err) {
                console.log(`saveBrandProposal error: ${err}`);
                return null;
            }
        }
        return;
    }

    obj.setCategoryCorrections = (dataIds, taxonomyId, sector, department, category) => {
        dataIds.forEach(dataId => {
            if (!dataIdValid(obj, dataId)) return;
            const key = `${taxonomyId ? taxonomyId : "x"}|${sector ? sector : "x"}|${department ? department : "x"}|${category ? category : "x"}`;
            obj.data[dataId].correction = {
                key, taxonomy_id: taxonomyId, sector: sector, department: department, category: category
            };
        });
    };
}

function dataIdValid(obj, dataId) {
    return obj.data.length > 0 && dataId >= 0 && dataId < obj.data.length;
}