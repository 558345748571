import React from "react";
import TextField from "@material-ui/core/TextField";

export default function Textbox({
    name,
    label,
    small,
    value,
    password,
    onChange,
    autoFocus,
}) {
    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <TextField
            id={`txt-${name}`}
            label={label}
            value={value}
            size={small ? "small" : undefined}
            variant={small ? "outlined" : "standard"}
            fullWidth={!small}
            type={password ? "password" : undefined}
            style={{ marginBottom: small ? 10 : 20 }}
            onChange={handleChange}
            autoFocus={autoFocus}
        />
    );
}
