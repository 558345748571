import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "15px 20px",
        borderRadius: 5,
        margin: "100px auto",
    },
    buttonSection: {
        height: 40,
        paddingTop: 5,
    },
    button: {
        float: "right",
    },
    cancel: {
        marginLeft: 10,
    },
}));

export default function Modalbox({
    open,
    title,
    children,
    onClose,
    onSave,
    disableSave,
    saveText = null,
    cancelText = null,
}) {
    const classes = useStyles();

    return (
        <div>
            <Modal open={open} onClose={onClose}>
                <div className={classes.paper}>
                    <h2>{title}</h2>
                    {children}
                    <div className={classes.buttonSection}>
                        <Button
                            variant="contained"
                            className={`${classes.button} ${classes.cancel}`}
                            onClick={onClose}
                        >
                            {cancelText ? cancelText : "Cancel"}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={onSave}
                            disabled={disableSave}
                        >
                            {saveText ? saveText : "Save"}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
