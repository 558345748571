import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            textAlign: "left",
        },
    })
);

export default function LabelledCheckbox({ name, label, value, onChange, width, style = { } }) {
    const classes = useStyles();

    return (
        <FormControlLabel
            width={width}
            style={style}
            className={classes.root}
            control={
                <Checkbox
                    checked={value}
                    onChange={(e) => {
                        onChange(e.target.checked);
                    }}
                    name={name}
                    color="primary"
                />
            }
            label={label}
        />
    );
}
