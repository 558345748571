import { createSlice } from "@reduxjs/toolkit";

function newUser() {
    return { roles: [], name: "", email: "" };
}

export const clientSlice = createSlice({
    name: "client",
    initialState: {
        initialized: false,
        signedIn: false,
        error: null,
        message: null,
        loading: false,
        user: newUser(),
        taxonomy: null,
    },
    reducers: {
        setInitialized: (state, action) => {
            state.initialized = true;
            state.user = action.payload.user;
        },
        setSignIn: (state, action) => {
            state.signedIn = true;
            if (action.payload.user) state.user = action.payload.user;
            if (!state.initialized) state.initialized = true;
        },
        setError: (state, action) => {
            state.error = action.payload.error;
            state.message = null;
        },
        setMessage: (state, action) => {
            state.message = action.payload.message;
            state.error = null;
        },
        clearMessage: (state, action) => {
            state.message = null;
            state.error = null;
        },
        setSignOut: (state, action) => {
            state.signedIn = false;
            state.user = newUser();
        },
        setTaxonomy: (state, action) => {
            state.taxonomy = action.payload.taxonomy;
            console.log(action.payload.taxonomy);
        },
    },
});

export const { setSignIn, setInitialized, setSignOut, setTaxonomy } = clientSlice.actions;
const { setError, setMessage, clearMessage } = clientSlice.actions;

export const showMessage = (message, dispatch) => {
    dispatch(setMessage({ message }));
    setTimeout(() => { dispatch(clearMessage()); }, 2000);
};

export const showError = (error, dispatch) => {
    dispatch(setError({ error }));
    setTimeout(() => { dispatch(clearMessage()); }, 2000);
};

export const selectInitialized = (state) => state.client.initialized;
export const selectSignedIn = (state) => state.client.signedIn;
export const selectUser = (state) => state.client.user;
export const selectError = (state) => state.client.error;
export const selectMessage = (state) => state.client.message;
export const selectTaxonomy = (state) => state.client.taxonomy;
export default clientSlice.reducer;
