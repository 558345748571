// notes:
// obj.data comes from base mixin
// mixin introduces following properties into data annotation
// selected: boolean => always true. however, not selected = undefined & selected = true.

export default function mixinSelection(obj) {
    obj.selected = (dataId) => {
        return dataIdValid(obj, dataId) && typeof obj.data[dataId].selected !== "undefined";
    };

    obj.numSelected = () => {
        if (obj.data.length === 0)
            return 0;
        return obj.data.reduce((prev, _cur, idx) => {
            return prev + (obj.selected(idx) ? 1 : 0);
        }, 0);
    };

    obj.setSelected = (checked, dataIds) => {
        const a = Array.isArray(dataIds) ? dataIds : [dataIds];
        let delta = 0;
        a.forEach(dataId => {
            if (!dataIdValid(obj, dataId))
                return;

            if (checked) {
                if (typeof obj.data[dataId].selected === "undefined") {
                    delta += 1;
                    obj.data[dataId].selected = true;
                }
                return;
            }

            if (typeof obj.data[dataId].selected !== "undefined") {
                delta -= 1;
                delete obj.data[dataId].selected;
            }
        });
        return delta;
    };

    obj.clearSelected = () => {
        obj.data.forEach(x => delete x.selected);
    };

    obj.allSelected = () => {
        return obj.data.reduce((prev, cur, idx) => {
            if (typeof cur.selected !== "undefined")
                prev.push(idx);
            return prev;
        }, []);
    };
}

function dataIdValid(obj, dataId) {
    return obj.data.length > 0 && dataId >= 0 && dataId < obj.data.length;
}