const MAX_PAGE_ROWS = 10;

// page = current page of annotations
// page id = id of current page.
// row id = id within the current page (0 to page length - 1).
// data id = id withing the entire set of annotation data (0 to length of all the annotations - 1).

export default class Paginator {
    constructor() {
        this.reset();
    }

    reset() {
        this.dataIds = [];
        this.pageId = -1;
        this.numPages = 0;
    }

    setDataIds(filteredDataIds) {
        this.dataIds = filteredDataIds;
        this.numPages = Math.floor(filteredDataIds.length / MAX_PAGE_ROWS);
        this.pageId = this.pageId < 0 || this.numPages === 0 ?
            0 : (this.pageId < this.numPages ? this.pageId : (this.numPages - 1));
        if (filteredDataIds.length % MAX_PAGE_ROWS > 0) this.numPages += 1;
    }

    pageRows(data) {
        if (data.length === 0)
            return [];

        const startId = this.pageId * MAX_PAGE_ROWS;
        const endId = startId + (this.pageId < this.numPages - 1 ? MAX_PAGE_ROWS : (this.dataIds.length - startId));
        const dataIds = this.dataIds.slice(startId, endId);

        return dataIds.map(dataId => {
            const d = data[dataId];
            return { ...d, data_id: dataId };
        });
    }

    prevPageRows(data) {
        const prevPageId = this.pageId - 1;
        if (prevPageId < 0 || prevPageId >= this.numPages) return null;
        this.pageId = prevPageId;
        return this.pageRows(data);
    }

    nextPageRows(data) {
        const nextPageId = this.pageId + 1;
        if (nextPageId < 0 || nextPageId >= this.numPages) return null;
        console.log(nextPageId);
        this.pageId = nextPageId;
        return this.pageRows(data);
    }

    pagePosition() {
        return this.pageId + 1;
    }
}