import mixinBase from "./mixinBase";
import mixinReviewer from "./mixinReviewer";
import mixinCorrector from "./mixinCorrector";
import mixinSelection from "./mixinSelection";

import {
    ANNOTATION_STATE_BRAND_REVIEW, ANNOTATION_STATE_CATEGORY_REVIEW,
    ANNOTATION_STATE_BRAND_CORRECTION, ANNOTATION_STATE_CATEGORY_CORRECTION,
    QUERY_ORDER_BY_RSD_ID, QUERY_ORDER_BY_COUNT_ID
} from "./constants";

export class BrandReviewer {
    constructor(prefix) {
        mixinBase(
            this, prefix, ANNOTATION_STATE_BRAND_REVIEW,
            "/annotation/brand/review", QUERY_ORDER_BY_COUNT_ID, true
        );
        mixinReviewer(this, true);
    }
}

export class CategoryReviewer {
    constructor(prefix) {
        mixinBase(
            this, prefix, ANNOTATION_STATE_CATEGORY_REVIEW,
            "/annotation/category/review", QUERY_ORDER_BY_COUNT_ID, true
        );
        mixinReviewer(this, false);
    }
}

export class BrandCorrector {
    constructor(prefix) {
        mixinBase(
            this, prefix, ANNOTATION_STATE_BRAND_CORRECTION,
            "/annotation/brand/correction", QUERY_ORDER_BY_RSD_ID, false
        );
        mixinSelection(this);
        mixinCorrector(this, true);
    }
}

export class CategoryCorrector {
    constructor(prefix) {
        mixinBase(
            this, prefix, ANNOTATION_STATE_CATEGORY_CORRECTION,
            "/annotation/category/correction", QUERY_ORDER_BY_COUNT_ID, true
        );
        mixinSelection(this);
        mixinCorrector(this, false);
    }
}