import React from "react";
import TextField from "@material-ui/core/TextField";

export default function Numbox({ name, label, value, small, onChange }) {
    const handleChange = (e) => {
        const re = /^[0-9]*$/g;
        if (e.target.value === "" || re.test(e.target.value)) {
            onChange(e.target.value);
        }
    };

    return (
        <TextField
            id={`txt-${name}`}
            label={label}
            value={value}
            size={small ? "small" : undefined}
            variant={small ? "outlined" : "standard"}
            fullWidth={!small}
            style={{ marginBottom: small ? 10 : 20 }}
            onChange={handleChange}
        />
    );
}
