import React from "react";

import {
    createStyles,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
} from "@material-ui/core";
import { Select } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
);

export default function Selector({
    id,
    label,
    value,
    selections,
    onChange,
    allowNone,
    width = null,
    disabled = null,
}) {
    const classes = useStyles();
    const formWidth = width ? width : 100;

    return (
        <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
            style={{ width: formWidth }}
            disabled={disabled}
        >
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                labelId={`${id}-select-label`}
                id={id}
                onChange={(event) => {
                    const curId = event.target.value;
                    const index = allowNone ? curId - 1 : curId;
                    if (index < 0) return;
                    onChange(index);
                }}
                value={value}
                label={label}
            >
                {allowNone ? (
                    <MenuItem key={`${id}-none`} value={0}>
                        <em>None</em>
                    </MenuItem>
                ) : undefined}
                {selections.map((current, i) => {
                    const index = allowNone ? i + 1 : i;
                    return (
                        <MenuItem
                            id={`${id}-${index}`}
                            key={`${id}-${index}`}
                            value={index}
                        >
                            {current}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
