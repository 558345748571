import Client from "./client";

const BASE_PATH = "/annotation/brand/review/autoset";

export default class BrandAutosetProposal {
    constructor() {
        this.data = [];
    }

    async getProposals(dispatch) {
        try {
            const data = await Client.authGet(BASE_PATH, dispatch);
            this.data = data;
            return true;
        } catch (err) {
            console.log(`setReviews error: ${err}`);
            return false;
        }
    }

    async approveProposal(id, dispatch) {
        if (id < 0 || id > this.data.length)
            return null;

        const autosetId = this.data[id].id;
        try {
            const msg = await Client.authPut(`${BASE_PATH}/${autosetId}`, {}, dispatch);
            return msg;
        } catch (err) {
            console.log(`BrandAutosetProposal approve error: ${err}`);
            return null;
        }
    }

    async rejectProposal(id, dispatch) {
        if (id < 0 || id > this.data.length)
            return null;

        if (this.data[id].approver_user_id)
            return null;

        const autosetId = this.data[id].id;
        try {
            const msg = await Client.authDelete(`${BASE_PATH}/${autosetId}`, dispatch);
            return msg;
        } catch (err) {
            console.log(`BrandAutosetProposal approve error: ${err}`);
            return null;
        }
    }
}