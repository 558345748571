
import Client from "./client";
import { ANNOTATION_STATE_BRAND_CORRECTION, ANNOTATION_STATE_BRAND_REVIEW } from "./constants";

export default class Stats {
    static async getStats(annotations, state, dispatch) {
        return state === ANNOTATION_STATE_BRAND_REVIEW || state === ANNOTATION_STATE_BRAND_CORRECTION ?
            await Stats.getBrandStats(annotations, state, dispatch) :
            await Stats.getTaxonomyStats(annotations, state, dispatch);
    }

    static async getBrandStats(annotations, state, dispatch) {
        try {
            const ids = annotations.reduce((prev, cur) => {
                const idString = `${cur.predicted_brand_id}`;
                if (!prev.includes(idString)) prev.push(idString);
                return prev;
            }, []);
            if (ids.length === 0) return Stats.blankList();
            return await Client.authGet(`/annotation/brand?state=${state}&ids=${ids.join(",")}`, dispatch);
        } catch (err) {
            console.log(`getBrandStats error: ${err}`);
            return Stats.blankList();
        }
    }

    static async getTaxonomyStats(annotations, state, dispatch) {
        try {
            if (annotations.length === 0) return Stats.blankList();
            const taxonomyId = annotations[0].predicted_taxonomy_id;
            const stats = await Client.authGet(`/taxonomy/stats?state=${state}&txid=${taxonomyId}`, dispatch);
            const name = `${stats.sector === "" ? "N/A" : stats.sector} / ${stats.department === "" ? "N/A" : stats.department} / ${stats.category === "" ? "N/A" : stats.category}`;
            return [{ name, count: stats.count }];
        } catch (err) {
            console.log(`getTaxonomyStats error: ${err}`);
            return Stats.blankList();
        }
    }

    static blankList() {
        return [{ name: "", num_reviewed_annotations: 0, num_total_annotations: 0 }];
    }
}