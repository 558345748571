import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MuiDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import BrandAutosetProposal from "../model/brandAutosetProposal";
import { Tooltip } from "@material-ui/core";
import { showMessage } from "../model/reduxClientSlice";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: "100%"
        },
    })
);

export default function BrandAutosetProposals() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const autoset = useRef(new BrandAutosetProposal());
    const [data, setData] = useState([]);

    async function fetchData() {
        await autoset.current.getProposals(dispatch);
        setData(autoset.current.data);
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (data.length === 0) {
        return "No autosets registered.";
    }

    return (
        <AutosetTable className={classes.root}
            data={data}
            onApprove={async (id) => {
                const message = await autoset.current.approveProposal(id, dispatch);
                if (message) {
                    fetchData();
                    showMessage(message, dispatch);
                }
            }}
            onReject={async (id) => {
                const message = await autoset.current.rejectProposal(id, dispatch);
                if (message) {
                    fetchData();
                    showMessage(message, dispatch);
                }
            }}
        />
    );
}

function AutosetTable({
    data,
    onApprove,
    onReject,
}) {
    const columns = [
        {
            name: "merchant_name",
            label: "Merchant",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps,
            },
        },
        {
            name: "receipt_short_description_prefix",
            label: "RSD prefix",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps,
            },
        },
        {
            name: "predicted_brands",
            label: "Brands",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps,
            },
        },
        {
            name: "approver_user_id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps,
            },
        },
    ];

    return (
        <MuiDataTable
            data={data}
            columns={columns}
            options={{
                filter: false,
                search: false,
                print: false,
                download: false,
                viewColumns: false,
                customToolbar: null,
                title: null,
                pagination: false,
                selectableRows: "none",
                responsive: "vertical",
                customRowRender: (_data, _dataIndex, rowIndex) => {
                    if (rowIndex > data.length - 1) return <div></div>;
                    return (
                        <AutosetRow
                            rowId={rowIndex}
                            autoset={data[rowIndex]}
                            onApprove={onApprove}
                            onReject={onReject}
                        />
                    );
                }
            }}
        />
    );
}

function AutosetRow({
    rowId,
    autoset,
    onApprove,
    onReject,
}) {
    return (
        <TableRow key={`table-row-${autoset.id}`}>
            <TableCell>{output(autoset.merchant_name)}</TableCell>
            <TableCell>{output(autoset.receipt_short_description_prefix)}</TableCell>
            <TableCell><TruncatedString string={autoset.predicted_brands.join(", ")} limit={75} /></TableCell>
            <TableCell>
                {autoset.approver_user_id ?
                    <div>N/A</div> :
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                onApprove(rowId);
                            }}>
                            Approve
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onReject(rowId);
                            }}>
                            Reject
                        </Button>
                    </div>
                }
            </TableCell>
        </TableRow>
    );
}

// HELPERS

function isBlank(string) {
    return string === undefined || string === null || string.length === 0;
}

function output(string) {
    return isBlank(string) ? "N/A" : string;
}

function setCellHeaderProps(value) {
    return {
        style: {
            fontWeight: "bold",
            color: "#212121",
        },
    };
};

function TruncatedString({ string, limit }) {
    if (string.length > limit)
        return (
            <Tooltip title={string} arrow>
                <div>{`${string.substring(0, limit + 1)} ..`}</div>
            </Tooltip>
        );
    return output(string);
}
