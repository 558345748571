import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 100,
        margin: "150px auto",
        padding: 20,
    },
}));

export default function Loading({ size = 40 }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress color="primary" size={size} />
        </div>
    );
}
