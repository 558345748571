import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import CheckIcon from '@material-ui/icons/Check';
import DescriptionIcon from '@material-ui/icons/Description';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import Selector from "../component/Selector";
import LabelledCheckbox from "../component/LabelledCheckbox";

import ModalQueryFilter from "./modalQueryFilter";
import ModalSave from "./modalSave";

const useStyles = makeStyles((theme) => {
    const padding = 10;
    return createStyles({
        root: {
            width: "100%",
        },

        content: {
            width: "100%",
        },

        statsLabel: {
            float: "left",
            fontWeight: 600,
            color: "gray",
            marginTop: 5,
        },

        statsDivider: {
            float: "left",
            margin: "0 10px",
        },

        editCorrections: {
            float: "right",
            margin: "-5px 12px 0 0",
            width: 200,
        },

        save: {
            float: "right",
            margin: "-5px 18px 0 0",
            width: 119,
        },

        editButton: {
            marginRight: padding,
            float: "left",
        },
    });
});

export default function CorrectionHeader({
    corrector,
    dataFilter,
    paginator,
    stats,
    numSelected,
    numCorrected,
    filterCount,
    rsdCharFilterList,
    onReload,
    onFilter,
    onSort,
    onEdit,
    onEditClear,
    onPageBack,
    onPageForward,
    onSave,
}) {
    const classes = useStyles();

    const [rsdOverride, setRSDOverride] = useState(dataFilter.rsdCharFilterId);
    const [filterLabel, setFilterLabel] = useState(dataFilter.filterLabel());

    const [sortBy, setSortBy] = useState(corrector.queryOrderBy);
    const [orderBy, setOrderBy] = useState(corrector.queryDesc);

    // modal
    const [openFilter, setOpenFilter] = useState(false);
    const [openSave, setOpenSave] = useState(false);

    useEffect(() => {
        onReload();
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.content} style={{ height: 60 }}>
                <div style={{ float: "left", marginLeft: -10, marginRight: -5 }}>
                    <Selector
                        id="id-order-by"
                        label="Sort by"
                        value={sortBy}
                        selections={["RSD", "Category", "Count", "Brand"]}
                        onChange={(id) => {
                            corrector.setQueryOrderBy(id);
                            onSort();
                            setSortBy(id);
                        }}
                    />
                </div>

                <div style={{ float: "left" }}>
                    <Selector
                        id="id-desc"
                        label="Order by"
                        value={orderBy}
                        selections={["ASC", "DESC"]}
                        onChange={(id) => {
                            corrector.setQueryDesc(id);
                            onSort();
                            setOrderBy(id)
                        }}
                    />
                </div>

                <Button
                    variant="contained"
                    color="default"
                    startIcon={<FilterListRoundedIcon />}
                    onClick={() => {
                        setOpenFilter(true);
                    }}
                    style={{ float: "left", marginTop: 9 }}
                >
                    {filterLabel}
                </Button>

                {
                    rsdCharFilterList ?
                    <div style={{ float: "left" }}>
                        <Selector
                            id="id-rsd-alphabet"
                            label="RSDs"
                            value={rsdOverride}
                            selections={rsdCharFilterList}
                            onChange={(id) => {
                                dataFilter.setRSDCharFilter(id, rsdCharFilterList);
                                setRSDOverride(id);
                                onFilter();
                        }}
                        />
                    </div> : undefined
                }

                {filterCount ? (
                    <Chip label={filterCount} color="primary" className={classes.filterCount} style={{
                        float: "left",
                        marginTop: 12,
                        color: "white",
                    }} />
                ) : undefined}
                <PageNavigationButton
                    corrector={corrector}
                    paginator={paginator}
                    onPageBack={onPageBack}
                    onPageForward={onPageForward}
                    setOpenSave={setOpenSave} />
                <LabelledCheckbox
                    id="cb-no-brand"
                    label="No brand"
                    value={corrector.queryNoBrand === 1}
                    onChange={(check) => {
                        corrector.setQueryNoBrand(check ? 1 : 0);
                        onReload();
                    }}
                    color="primary"
                    width={60}
                    style={{ float: "right", marginTop: 8, marginRight: 10 }}
                />
            </div>

            <div className={classes.content} style={{ height: 40 }}>
                {numSelected === 0 ?
                    <div style={{ float: "left", height: 25 }}>
                        {stats.length > 1 ?
                            <Tooltip title={displayStats(stats)}>
                                <div className={classes.statsLabel}>
                                    {stats[0].name.length === 0 ? "N/A" : stats[0].name}: {stats[0].count}
                                </div>
                            </Tooltip> :
                            <div className={classes.statsLabel}>
                                {stats[0].name.length === 0 ? "N/A" : stats[0].name}: {stats[0].count}
                            </div>
                        }
                        <Divider orientation="vertical" className={classes.statsDivider} />
                        <div className={classes.statsLabel}>Selected: {numSelected}</div>
                        <Divider orientation="vertical" className={classes.statsDivider} />
                        <div className={classes.statsLabel}>Changed: {numCorrected}</div>
                        <Divider orientation="vertical" className={classes.statsDivider} />
                        <div className={classes.statsLabel}>
                            Total: {`${numCorrected} / ${corrector.dataLength()}`}
                        </div>
                    </div> :
                    <div style={{ float: "left", height: 25 }}>
                        {stats.length > 1 ?
                            <Tooltip title={displayStats(stats)}>
                                <div className={classes.statsLabel}>
                                    {stats[0].name.length === 0 ? "N/A" : stats[0].name}: {stats[0].count}
                                </div>
                            </Tooltip> :
                            <div className={classes.statsLabel}>
                                {stats[0].name.length === 0 ? "N/A" : stats[0].name}: {stats[0].count}
                            </div>
                        }
                        <Divider orientation="vertical" className={classes.statsDivider} />
                        <Button
                            onClick={onEdit}
                            className={classes.editButton}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Edit
                        </Button>
                        <Button
                            onClick={onEditClear}
                            className={classes.editButton}
                            variant="contained"
                            size="small"
                        >
                            Clear
                        </Button>
                        <div className={classes.statsLabel}>Selected: {numSelected}</div>
                        <Divider orientation="vertical" className={classes.statsDivider} />
                        <div className={classes.statsLabel}>Changed: {numCorrected}</div>
                        <Divider orientation="vertical" className={classes.statsDivider} />
                        <div className={classes.statsLabel}>
                            Total: {`${numCorrected} / ${corrector.dataLength()}`}
                        </div>
                    </div>
                }

                <div style={{ float: "right" }}>
                    <IconButton
                        onClick={onPageForward}
                        aria-label="Forward"
                        size="small"
                        style={{ float: "right" }}
                    >
                        <ArrowRightIcon />
                    </IconButton>

                    <IconButton
                        onClick={onPageBack}
                        aria-label="Back"
                        size="small"
                        style={{ float: "right" }}
                    >
                        <ArrowLeftIcon />
                    </IconButton>

                    <div style={{ float: "right", height: 25 }}>
                        <div className={classes.statsLabel} style={{ marginRight: 10 }}>
                            {`Page ${paginator.pagePosition()} of ${paginator.numPages}`}
                        </div>
                    </div>
                </div>

            </div>

            {
                openFilter ? (
                    <ModalQueryFilter
                        open={openFilter}
                        data={corrector.data}
                        dataFilter={dataFilter}
                        onSave={(receiptShortDescription, merchantName, numSales, category) => {
                            dataFilter.setRSDFilter(receiptShortDescription);
                            dataFilter.setMerchantFilter(merchantName);
                            dataFilter.setSalesFilter(numSales);
                            dataFilter.setCategoryFilter(category);
                            setFilterLabel(dataFilter.filterLabel());
                            onFilter();
                            setOpenFilter(false);
                        }}
                        onClose={() => {
                            setOpenFilter(false);
                        }}
                    />
                ) : undefined
            }

            {
                openSave ? (
                    <ModalSave
                        open={openSave}
                        onSave={() => {
                            onSave(corrector.queryString());
                            setOpenSave(false);
                        }}
                        onClose={() => {
                            setOpenSave(false);
                        }}
                    />
                ) : undefined
            }
        </div >
    );
}

function displayStats(stats) {
    return stats.map((cur, idx) => {
        if (idx === 0) return null;
        return <div>{cur.name.length === 0 ? "N/A" : `${cur.name}: ${cur.count}`}</div>;
    }, null);
}

function PageNavigationButton({ corrector, paginator, onPageBack, onPageForward, setOpenSave }) {
    if (corrector.numCorrections() > 0) {
        return <Button
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
            onClick={() => { setOpenSave(true); }}
            style={{ float: "right", marginTop: 9 }}
        >
            Save changes
        </Button>
    }
    return <Button
        variant="outlined"
        color="primary"
        disabled={paginator.numPages < 2}
        startIcon={<DescriptionIcon />}
        onClick={() => {
            paginator.pagePosition() === paginator.numPages ? onPageBack() : onPageForward();
        }}
        style={{ float: "right", marginTop: 9, marginRight: 10 }}
    >
        {paginator.numPages > 1 && paginator.pagePosition() === paginator.numPages ? "Previous page" : "Next page"}
    </Button>
}