import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Client from "../model/client";

import Textbox from "../component/Textbox";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            margin: "100px auto 0 auto",
            width: 400,
            height: 300,
            padding: "30px 50px",
            borderRadius: 15,
            border: "2px solid lightgrey",
        },
        field: {
            marginBottom: 20,
        },
        error: {
            color: "#A50209",
        },
    })
);

export default function SignInPage() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    return (
        <div className={classes.root}>
            <h2>Please sign in</h2>
            <form
                noValidate
                autoComplete="off"
                onSubmit={async (e) => {
                    e.preventDefault();
                    await Client.signIn(email, password, dispatch);
                }}
            >
                <Textbox
                    name="email"
                    label="Email"
                    value={email}
                    onChange={(value) => {
                        setEmail(value);
                    }}
                    autoFocus
                />

                <Textbox
                    name="password"
                    label="Password"
                    value={password}
                    password
                    onChange={(value) => {
                        setPassword(value);
                    }}
                />

                <Button variant="contained" color="primary" type="submit" style={{ float: "right" }}>
                    Sign in
                </Button>
            </form>
        </div>
    );
}
