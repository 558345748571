import React, { useRef, useState } from "react";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useDispatch } from "react-redux";

import Loading from "../component/Loading";
import CorrectionHeader from "./correctionHeader";
import CorrectionTable from "./correctionTable";
import ModalBrandChange from "./modalBrandChange";
import ModalTaxonomyChange from "./modalTaxonomyChange";
import ModalBrandProposal from "./modalBrandProposal";
import { showMessage, showError } from "../model/reduxClientSlice";
import Stats from "../model/stats";
import { BrandCorrector, CategoryCorrector } from "../model/mixinModels";
import DataFilter from "../model/dataFilter";
import Paginator from "../model/paginator";

const useStyles = makeStyles((theme) => {
    const padding = 10;
    return createStyles({
        root: {
            width: "100%"
        },
        headerRoot: {
            width: "100%",
            height: 125,
            borderBottom: "1px solid lightgray",
        },
        headerContent: {
            height: 60,
        },
        filter: {
            float: "left",
            marginTop: 19,
        },
        filterCount: {
            float: "left",
            marginTop: 23,
            marginLeft: -5,
            backgroundColor: "rgb(33, 150, 243)",
            color: "white",
        },
        removeSpacing: {
            marginLeft: -20,
        },
        switches: {
            marginTop: padding,
        },
        title: {
            fontWeight: "bold",
        },
        reviewCheckbox: {
            marginTop: 10,
            marginRight: -15,
        },
        stats: {
            float: "left",
            marginLeft: 20,
            marginTop: 5,
            height: 25,
        },
        statsLabel: {
            float: "left",
            fontWeight: 600,
            marginTop: 2,
        },
        statsDivider: {
            float: "left",
            margin: "0 10px",
        },
        editCorrections: {
            float: "right",
            margin: "-5px 12px 0 0",
            width: 200,
        },
        save: {
            float: "right",
            margin: "-5px 18px 0 0",
            width: 119,
        },
        editSection: {
            float: "left",
            marginLeft: 8,
            padding: 1,
        },
        editButton: {
            marginLeft: padding,
        },
    });
});

export default function CorrectionPage({ isBrand }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const brandPrefix = "brand_correction";
    const categoryPrefix = "category_correction";

    const corrector = useRef(isBrand ? new BrandCorrector(brandPrefix) : new CategoryCorrector(categoryPrefix));
    const dataFilter = useRef(new DataFilter(isBrand ? brandPrefix : categoryPrefix));
    const paginator = useRef(new Paginator());

    const [stats, setStats] = useState(Stats.blankList());
    const [loading, setLoading] = useState(false);

    const [rows, setRows] = useState([]);
    const [numSelected, setNumSelected] = useState(0);
    const [numCorrected, setNumCorrected] = useState(0);
    const [filterCount, setFilterCount] = useState(null);
    const [rsdCharFilterList, setRSDCharFilterList] = useState(null);

    const [modalAnnotation, setModalAnnotation] = useState(null);
    const [modalBrandProposals, setModalBrandProposals] = useState(null);

    async function setPage(data) {
        paginator.current.setDataIds(dataFilter.current.filter(data));
        setRows(paginator.current.pageRows(corrector.current.data));
        setStats(await Stats.getStats(corrector.current.data, corrector.current.state, dispatch));
        setNumSelected(corrector.current.numSelected());
        setNumCorrected(corrector.current.numCorrections());
        setFilterCount(dataFilter.current.filterCount());
        setRSDCharFilterList(dataFilter.current.rsdCharFilterList(data));
    }

    async function loadPage() {
        setLoading(true);
        const data = await corrector.current.getData(dispatch);
        setLoading(false);
        if (!data) return;
        paginator.current.reset();
        setPage(data);
    }

    return (
        <div className={classes.root}>
            <CorrectionHeader
                corrector={corrector.current}
                dataFilter={dataFilter.current}
                paginator={paginator.current}
                stats={stats}
                numSelected={numSelected}
                numCorrected={numCorrected}
                filterCount={filterCount}
                rsdCharFilterList={rsdCharFilterList}
                onReload={loadPage}
                onFilter={() => {
                    paginator.current.setDataIds(dataFilter.current.filter(corrector.current.data));
                    setRows(paginator.current.pageRows(corrector.current.data));
                    setFilterCount(dataFilter.current.filterCount());
                }}
                onSort={() => {
                    if (corrector.current.dataLength() === 0) {
                        loadPage();
                        return;
                    }
                    const sorted = corrector.current.sortData();
                    if (!sorted) return;
                    paginator.current.setDataIds(dataFilter.current.filter(corrector.current.data));
                    setRows(paginator.current.pageRows(corrector.current.data));
                }}
                onPageBack={() => {
                    const prevRows = paginator.current.prevPageRows(corrector.current.data);
                    if (prevRows) setRows(prevRows);
                }}
                onPageForward={() => {
                    const nextRows = paginator.current.nextPageRows(corrector.current.data);
                    if (nextRows) setRows(nextRows);
                }}
                onEdit={() => setModalAnnotation({ data_id: null })}
                onEditClear={() => {
                    corrector.current.clearSelected();
                    setNumSelected(0);
                    setRows(paginator.current.pageRows(corrector.current.data));
                }}
                onSave={async () => {
                    const res = await corrector.current.saveCorrections(dispatch);
                    if (isBrand) {
                        if (res.unprocessed.length > 0) {
                            if (res.processed.length > 0) {
                                corrector.current.removeCorrectedAnnotations(res.processed);
                                await setPage(corrector.current.data);
                            }
                            setModalBrandProposals(res.unprocessed);
                            return;
                        }

                        if (res.processed.length > 0) {
                            corrector.current.removeCorrectedAnnotations(res.processed);
                            await corrector.current.data.length === 0 ? loadPage() : setPage(corrector.current.data);
                            showMessage("Brand change successful", dispatch);
                            return;
                        }

                        showError("Brand change failed", dispatch);
                        return;
                    }

                    if (res) {
                        corrector.current.removeCorrectedAnnotations(res);
                        await corrector.current.data.length === 0 ? loadPage() : setPage(corrector.current.data);
                        showMessage("Category change successful", dispatch);
                        return;
                    }

                    showError("Category change failed", dispatch);
                }}
            />

            {loading ? (
                <Loading size={70} />
            ) : (
                <div>
                    <CorrectionTable
                        rows={rows}
                        isBrand={isBrand}
                        onEdit={(annotation) => setModalAnnotation(annotation)}
                        onRowChecked={(checked, dataId) => {
                            const delta = corrector.current.setSelected(checked, dataId);
                            setNumSelected(numSelected + delta);
                            setRows(paginator.current.pageRows(corrector.current.data));
                        }}
                        onAllRowsChecked={(checked) => {
                            const dataIds = rows.map(x => x.data_id);
                            const delta = corrector.current.setSelected(checked, dataIds);
                            setNumSelected(numSelected + delta);
                            setRows(paginator.current.pageRows(corrector.current.data));
                        }}
                    />
                </div>
            )}

            {
                modalAnnotation ? (isBrand ?
                    <ModalBrandChange
                        annotation={modalAnnotation}
                        open={modalAnnotation !== null}
                        onSave={(dataId, catalogBrandId, catalogBrand) => {
                            const dataIds = dataId !== null ? [dataId] : corrector.current.allSelected();
                            corrector.current.setBrandCorrections(dataIds, catalogBrandId, catalogBrand);
                            setModalAnnotation(null);
                            setRows(paginator.current.pageRows(corrector.current.data));
                            setNumCorrected(corrector.current.numCorrections());
                        }}
                        onClose={() => {
                            setModalAnnotation(null);
                        }}
                    /> :
                    <ModalTaxonomyChange
                        annotation={modalAnnotation}
                        open={modalAnnotation !== null}
                        onSave={(dataId, taxonomyId, sector, department, category) => {
                            const dataIds = dataId !== null ? [dataId] : corrector.current.allSelected();
                            corrector.current.setCategoryCorrections(dataIds, taxonomyId, sector, department, category);
                            setModalAnnotation(null);
                            setRows(paginator.current.pageRows(corrector.current.data));
                            setNumCorrected(corrector.current.numCorrections());
                        }}
                        onClose={() => {
                            setModalAnnotation(null);
                        }}
                    />)
                    : undefined
            }

            {
                modalBrandProposals ?
                    <ModalBrandProposal
                        brandProposals={modalBrandProposals}
                        open={modalBrandProposals !== null}
                        onSave={async () => {
                            const res = await corrector.current.saveBrandProposal(modalBrandProposals, dispatch);
                            if (res.processed.length > 0) {
                                corrector.current.removeCorrectedAnnotations(res.processed);
                                await corrector.current.data.length === 0 ? loadPage() : setPage(corrector.current.data);
                                setModalBrandProposals(null);
                                res.unprocessed.length > 0 ?
                                    showMessage("Brand proposal partially successful", dispatch) :
                                    showMessage("Brand proposal successful", dispatch);
                                return;
                            }
                            setModalBrandProposals(null);
                            showError("Brand proposal failed", dispatch);
                        }}
                        onClose={() => {
                            setModalBrandProposals(null);
                        }}
                    />
                    : undefined
            }
        </div>
    );
}