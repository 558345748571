import React from "react";
import Modalbox from "../component/Modalbox";

export default function ModalSave({ open, onClose, onSave }) {
    return (
        <Modalbox
            title={`Save Confirmation`}
            open={open}
            onClose={onClose}
            onSave={onSave}
        >
            Are you sure you want to save your changes?
        </Modalbox>
    );
}
