const BASE_PATH = '/dashboard';

export default class PathLink {
    static encodeText(text) {
        return `${BASE_PATH}/${text.replace(" ", "-")}`;
    }

    static encodePaths(paths) {
        return paths.reduce((prev, cur) => {
            return `${prev}/${cur.replace(" ", "-")}`;
        }, BASE_PATH);
    }

    static decode(url) {
        let curPath = `${BASE_PATH}`;
        const a = url
            .replace(`${BASE_PATH}/`, "")
            .split("/");

        return a.map((x, idx) => {
            const rawString = x.replace("-", " ");
            if (idx === a.length - 1) return { text: `${rawString.charAt(0).toUpperCase()}${rawString.slice(1)}`, path: "" };
            curPath = `${curPath}/${x}`;
            return { text: `${rawString.charAt(0).toUpperCase()}${rawString.slice(1)}`, path: curPath };
        });
    }
}

export const SIGNIN_PATH = "/signin";
export const BRAND_REVIEW_PATH = PathLink.encodeText("brand-review");
export const BRAND_REVIEW_AUTOSET_PATH = PathLink.encodeText("brand-review-autoset");
export const BRAND_CORRECTION_PATH = PathLink.encodeText("brand-correction");
export const CATEGORY_REVIEW_PATH = PathLink.encodeText("category-review");
export const CATEGORY_CORRECTION_PATH = PathLink.encodeText("category-correction");
