import React from "react";
import Modalbox from "../component/Modalbox";

export default function ModalBrandProposal({ open, brandProposals, onClose, onSave }) {
    const brandNames = brandProposals.map(x => x.catalog_brand);
    console.log(brandNames);
    const prompt = brandNames.length > 1 ?
        "The following brands are not in our system:" :
        "The following brand is not in our system:";

    return (
        <Modalbox
            title={`Brand Proposal`}
            open={open}
            onClose={onClose}
            onSave={onSave}
            saveText="Yes"
            cancelText="No"
        >
            <div>{prompt}</div>
            <ul>
                {brandNames.map(x => <li><b>{x}</b></li>)}
            </ul>
            <div>Would you like to recommend them to be added?</div>
        </Modalbox>
    );
}
