import React from "react";
import { TextField } from "@material-ui/core";
import { useDebouncedCallback } from "use-debounce";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function AutoQuerybox({
    value,
    label,
    small,
    options,
    onChange,
    onDebounce,
    autoFocus,
    disabled = false,

}) {
    const debounced = useDebouncedCallback(
        (value) => {
            onDebounce(value);
        },
        200 // delay in ms
    );

    const style = { marginBottom: small ? 10 : 20 };
    if (small) style.width = 192;

    return (
        <Autocomplete
            freeSolo
            id={`id-${label}`}
            options={options}
            disabled={disabled}
            value={value}
            onChange={(_event, value, reason) => {
                if (reason === "select-option") onChange(value);
                else if (reason === "clear") onChange("");
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    fullWidth={!small}
                    size={small ? "small" : undefined}
                    variant={small ? "outlined" : "standard"}
                    style={style}
                    onChange={(e) => {
                        onChange(e.target.value);
                        debounced.callback(e.target.value);
                    }}
                    autoFocus={autoFocus}
                />
            )}
        />
    );
}
