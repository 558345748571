import Client from "./client";

// notes:
// obj.data comes from base mixin
// obj.baseUrl comes from base mixin

// mixin introduces following properties into data annotation
// approved: boolean

export default function mixinReviewer(obj, isBrand) {
    obj.setApproved = (dataId, value) => {
        const delta = { numApproved: 0, numRejected: 0 };

        if (obj.data.length === 0 || dataId < 0 || dataId >= obj.data.length)
            return delta;

        if (typeof obj.data[dataId].approved === "undefined") {
            delta.numApproved = value ? 1 : 0;
            delta.numRejected = value ? 0 : 1;
        } else if (obj.data[dataId].approved) {
            delta.numApproved = value ? 0 : -1;
            delta.numRejected = value ? 0 : 1;
        } else {
            delta.numApproved = value ? 1 : 0;
            delta.numRejected = value ? -1 : 0;
        }

        obj.data[dataId].approved = value;
        return delta;
    };

    obj.confirmRows = (rows) => {
        if (obj.data.length === 0) return;
        rows.forEach(row => {
            if (row.data_id < 0 || row.data_id >= obj.data.length) return;
            if (typeof obj.data[row.data_id].approved !== "undefined" && !obj.data[row.data_id].approved) return;
            obj.data[row.data_id].approved = true;
        });
    };

    obj.numApproved = () => {
        return obj.data.reduce((prev, cur, _idx) => {
            return prev + (typeof cur.approved !== "undefined" && cur.approved ? 1 : 0);
        }, 0);
    };

    obj.numRejected = () => {
        return obj.data.reduce((prev, cur, _idx) => {
            return prev + (typeof cur.approved !== "undefined" && !cur.approved ? 1 : 0);
        }, 0);
    };

    obj.saveReviews = async (dispatch) => {
        const approved = obj.data.reduce((prev, cur, _idx) => {
            if (typeof cur.approved !== "undefined" && cur.approved)
                prev.push(cur.key);
            return prev
        }, []);

        const rejected = obj.data.reduce((prev, cur, _idx) => {
            if (typeof cur.approved !== "undefined" && !cur.approved)
                prev.push(cur.key);
            return prev
        }, []);

        if (approved.length === 0 && rejected.length === 0)
            return false;

        try {
            await Client.authPut(obj.baseUrl, { approved, rejected }, dispatch);
            return true;
        } catch (err) {
            console.log(`setReviews error: ${err}`);
            return false;
        }
    };

    obj.pageConfirmed = (rows) => {
        let confirmed = true;
        for (let i = 0; i < rows.length; i++) {
            if (typeof rows[i].approved === "undefined") {
                confirmed = false;
                break;
            }
        }
        return confirmed;
    };

    obj.allConfirmed = () => {
        if (obj.data.length === 0)
            return false;

        let confirmed = true;
        for (let i = 0; i < obj.data.length; i++) {
            if (typeof obj.data[i].approved === "undefined") {
                confirmed = false;
                break;
            }
        }

        return confirmed;
    };

    if (!isBrand) return;

    obj.sendBrandAutosetProposal = async (annotation, dispatch) => {
        try {
            await Client.authPost("/annotation/brand/review/autoset", { annotation_key: annotation.key }, dispatch);
            const prefix = annotation.receipt_short_description.split(" ")[0];
            const merchant = annotation.merchant_name;
            return { prefix, merchant }
        } catch (err) {
            console.log(`sendBrandAutosetProposal error: ${err}`);
            return null;
        }
    }

    obj.removeAnnotationsByRSDPrefix = (prefix, merchant) => {
        const p = prefix.toLowerCase();
        const a = obj.data.filter(x => {
            return !(x.receipt_short_description.split(" ")[0].toLowerCase() === p && x.merchant_name === merchant);
        });
        obj.data = a;
    };
}