import React, { useState } from "react";
import MuiDataTable from "mui-datatables";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
    return createStyles({
        row: {
            position: "relative",
            cursor: "pointer",
            borderTop: "1px solid #E4E4E4",
        },
        rowApproved: {
            position: "relative",
            cursor: "pointer",
            borderTop: "1px solid #E4E4E4",
            backgroundColor: "#E8FCF4",
        },
        rowRejected: {
            position: "relative",
            cursor: "pointer",
            borderTop: "1px solid #E4E4E4",
            backgroundColor: "#FFEEEB",
        },
        rowCellBold: {
            fontWeight: "bold",
            color: "#474749",
        },
        divider: {
            float: "left",
            margin: "0 5px",
            height: 20,
            background: "#212121",
        },
        taxonomy: {
            float: "left",
        },
        hoverButtons: {
            position: "absolute",
            top: 10,
            right: 15,
            bottom: 10,
        },
    });
});

// REVIEW TABLE

export default function ReviewTable({ rows, isBrand, onRowClick, onAutoset }) {
    const columns = [
        {
            name: "merchant_name",
            label: "Retailer",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps,
            },
        },
        {
            name: "receipt_short_description",
            label: "RSD",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps,
            },
        },
        {
            name: "current_brand",
            label: "Brand",
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps,
            },
        },
    ];

    isBrand ? appendBrandColumns(columns) : appendCategoryColumns(columns);

    columns.push({
        name: "num_sales",
        label: "Count",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });

    return (
        <MuiDataTable
            data={rows}
            columns={columns}
            options={{
                filter: false,
                search: false,
                print: false,
                download: false,
                viewColumns: false,
                customToolbar: null,
                title: null,
                pagination: false,
                selectableRows: "none",
                responsive: "vertical",
                customRowRender: isBrand ?
                    renderBrandRow(rows, onRowClick, onAutoset) :
                    renderCategoryRow(rows, onRowClick),
            }}
        />
    );
}

// BRAND ROWS

function appendBrandColumns(columns) {
    columns.push({
        name: "product_name",
        label: "Product",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });
    columns.push({
        name: "sector",
        label: "Category Path",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });
}

function renderBrandRow(rows, onClick, onAutoset) {
    return (_data, dataIndex, _rowIndex) => {
        return dataIndex >= rows.length ?
            <div></div> :
            <BrandReviewRow
                key={`review-table-${dataIndex}`} row={rows[dataIndex]} onClick={onClick} onAutoset={onAutoset}
            />;
    };
}

// row => { data_id, approved, ... annotation data }
// data_id => index of annotation in data array
// approved => undefined = not reviewed, true = approved, false = rejected
function BrandReviewRow({ row, onClick, onAutoset }) {
    const classes = useStyles();
    const [showButtons, setShowButtons] = useState(false);

    const cellClick = () => {
        const value = typeof row.approved === "undefined" ? false : !row.approved;
        onClick(row.data_id, value);
    };

    const onHover = (show) => {
        return () => { if (onAutoset) setShowButtons(show); };
    };

    return (
        <TableRow
            onMouseEnter={onHover(true)}
            onMouseLeave={onHover(false)}
            className={
                typeof row.approved === "undefined" ?
                    classes.row : (row.approved ? classes.rowApproved : classes.rowRejected)
            }
        >
            <TableCell onClick={cellClick}>{output(row.merchant_name)}</TableCell>
            <TableCell onClick={cellClick}>{output(row.receipt_short_description)}</TableCell>
            <TableCell onClick={cellClick} className={classes.rowCellBold}>
                {output(row.current_brand)}
            </TableCell>
            <TableCell onClick={cellClick}>
                <TruncatedString string={row.predicted_product_name} limit={40}></TruncatedString>
            </TableCell>
            <TableCell onClick={cellClick}>
                <CategoryPath
                    classes={classes}
                    sector={row.current_sector}
                    department={row.current_department}
                    category={row.current_category}
                />
            </TableCell>
            <TableCell>
                <div onClick={cellClick} style={{ visibility: onAutoset && showButtons ? "hidden" : "visible" }}>
                    {output(row.num_sales)}
                </div>
                {
                    onAutoset && showButtons ?
                        <AutoSetButton classes={classes} onClick={() => { onAutoset(row.data_id); }} />
                        : undefined
                }
            </TableCell>
        </TableRow >
    );
}

function AutoSetButton({ classes, onClick }) {
    return (
        <div className={classes.hoverButtons}>
            <Button
                component="span"
                size="small"
                onClick={onClick}
                color="primary"
                variant="contained"
            >
                Autoset
            </Button>
        </div>
    );
}

function CategoryPath({ classes, sector, department, category }) {
    if (isBlank(sector)) {
        return <div></div>;
    }

    if (isBlank(department)) {
        return (
            <div>
                <div className={classes.taxonomy}>{sector}</div>
            </div>
        );
    }

    if (isBlank(category)) {
        return (
            <div>
                <div className={classes.taxonomy}>{sector}</div>
                <Divider orientation="vertical" className={classes.divider} />
                <div className={classes.taxonomy}>{department}</div>
            </div>
        );
    }

    return (
        <div>
            <div className={classes.taxonomy}>{sector}</div>
            <Divider orientation="vertical" className={classes.divider} />
            <div className={classes.taxonomy}>{department}</div>
            <Divider orientation="vertical" className={classes.divider} />
            <div className={classes.taxonomy}>{category}</div>
        </div>
    );
}

// CATEGORY ROWS

function appendCategoryColumns(columns) {
    columns.push({
        name: "product_name",
        label: "Product",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });
    columns.push({
        name: "current_sector",
        label: "Sector",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });
    columns.push({
        name: "current_department",
        label: "Department",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });
    columns.push({
        name: "current_category",
        label: "Major Category",
        options: {
            filter: false,
            sort: false,
            setCellHeaderProps,
        },
    });
}

function renderCategoryRow(rows, onClick) {
    return (_data, dataIndex, _rowIndex) => {
        return dataIndex >= rows.length ?
            <div></div> :
            <CategoryRow key={`review-table-${dataIndex}`} row={rows[dataIndex]} onClick={onClick} />;
    };
}

// row => { data_id, approved, ... annotation data }
// data_id => index of annotation in data array
// approved => undefined = not reviewed, true = approved, false = rejected
function CategoryRow({ row, onClick }) {
    const classes = useStyles();

    const cellClick = () => {
        const value = typeof row.approved === "undefined" ? false : !row.approved;
        onClick(row.data_id, value);
    };

    return (
        <TableRow className={
            typeof row.approved === "undefined" ?
                classes.row : (row.approved ? classes.rowApproved : classes.rowRejected)
        }>
            <TableCell onClick={cellClick}>{output(row.merchant_name)}</TableCell>
            <TableCell onClick={cellClick}>{output(row.receipt_short_description)}</TableCell>
            <TableCell onClick={cellClick}>{output(row.current_brand)}</TableCell>
            <TableCell onClick={cellClick}>
                <TruncatedString string={row.predicted_product_name} limit={40}></TruncatedString>
            </TableCell>
            <TableCell onClick={cellClick} className={classes.rowCellBold}>
                {output(row.current_sector)}
            </TableCell>
            <TableCell onClick={cellClick} className={classes.rowCellBold}>
                {output(row.current_department)}
            </TableCell>
            <TableCell onClick={cellClick} className={classes.rowCellBold}>
                {output(row.current_category)}
            </TableCell>
            <TableCell onClick={cellClick}>{output(row.num_sales)}</TableCell>
        </TableRow>
    );
}

// HELPERS

function isBlank(string) {
    return string === undefined || string === null || string.length === 0;
}

function output(string) {
    return isBlank(string) ? "N/A" : string;
}

function TruncatedString({ string, limit }) {
    if (string.length > limit)
        return (
            <Tooltip title={string} arrow>
                <div>{`${string.substring(0, limit + 1)} ..`}</div>
            </Tooltip>
        );
    return output(string);
}

function setCellHeaderProps(value) {
    return {
        style: {
            fontWeight: "bold",
            color: "#212121",
        },
    };
};