import Client from "./client";

export default class Taxonomy {
    constructor(taxonomy) {
        console.log(taxonomy);
        this.sectorList = taxonomy.sectors;
        this.departmentMap = taxonomy.departments;
        this.categoryMap = taxonomy.categories;
        this.searchKeyMap = taxonomy.search_key_map;
    }

    sectors() {
        return this.sectorList;
    }

    departments(sector) {
        return this.departmentMap.hasOwnProperty(sector) ? this.departmentMap[sector] : [];
    }

    categories(sector, department) {
        const sectorDepartment = `${sector}|${department}`;
        return this.categoryMap.hasOwnProperty(sectorDepartment) ? this.categoryMap[sectorDepartment] : [];
    }

    idFromSelection(sector, department, category) {
        const searchKey = `${sector}|${department}|${category}`.toLowerCase();
        return this.searchKeyMap.hasOwnProperty(searchKey) ? this.searchKeyMap[searchKey] : -1;
    }

    static async getTaxonomy(dispatch) {
        try {
            const res = await Client.authGet("/taxonomy", dispatch);
            return new Taxonomy(res.taxonomy);
        } catch (err) {
            console.log(`getTaxonomy error: ${err}`);
            return null;
        }
    }
}
